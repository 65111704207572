.Audio {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 100px;
}

.Audio p {
    color: white;
    padding-bottom: 24px;
}

.Audio a,
.Audio a:hover,
.Audio a:active,
.Audio a.active {
    color: white;
}

.AudioTitle {
    font-size: 36px;
    font-weight: bold;
    color: white;
    /* padding-bottom: 20px; */
}

/*
@media (max-width: 812px) {
    .Audio {
        display: inline-block;
    }
}
*/
