@font-face {
    font-family: 'Trajan Pro Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Trajan Pro Regular'), url(/static/media/trajan-pro-2.af3e5307.woff) format('woff');
}

@font-face {
    font-family: 'Futura Condensed PT Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Condensed PT Medium'), url(/static/media/futura-condensed-pt-medium-589e44ed1e3a5.12102e6c.woff) format('woff');
}

@font-face {
    font-family: 'Futura PT Light';
    font-style: normal;
    font-weight: normal;
    src: local('Futura PT Light'), url(/static/media/futura-pt-light-589a6e187563a.09fd7959.woff) format('woff');
}

/*
@font-face {
    font-family: 'La Gioconda';
    font-style: normal;
    font-weight: normal;
    src: local('La Gioconda'), url('assets/fonts/la-gioconda-tt.woff2') format('woff');
}
*/

body {
    margin: 0;
    padding: 0;
    /* font-family: "Avenir Next","Helvetica Neue",Helvetica,Arial,sans-serif; */
    background-color: #000000;

    text-transform: uppercase;
    font-family: "Trajan Pro Regular", serif;
    letter-spacing: 0px;
    color: white;
}

.background {
    position: fixed;
    overflow: visible;
    top: 0;
    right: 0;
    left: 0;
    height: 110%;
    width: 100%;
    z-index: -1;
    background-position: 50% 50%;
    background-size: cover;
    background-color: #121b25;
    background-image: url(/static/media/BG_DESKTOP_dark-c@1x.ba57392d.jpg);
    background-image: -webkit-image-set(url(/static/media/BG_DESKTOP_dark-c@1x.ba57392d.jpg) 1x, url(/static/media/BG_DESKTOP_dark-b@2x.37a95a66.jpg) 2x);
}

.Layout_Content__1ZCI9 {
    /* margin-top: 72px; */
    position: relative;
    margin: 50px auto;
    padding: 20px 0;
    text-align: center;

    max-width: 100% !important;
    overflow-x: hidden !important;
}

/*
.Ribbon {
    top: -1px;
    right: 0px;

}

.RibbonText {
    font-size: 12px;
    text-transform: none;
}
*/

.Product_Product__2vNml {
    margin: auto;
    /*
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    */
    /*
    background-color: white;
    box-sizing: border-box;
    border-radius: 5px;
    */
    /* width: 80%; */
    /* max-width: 800px; */
    /* padding: 8px; */
    padding-top: 20px;
    color: white;
    /* font-size: 12px; */
    font-weight: normal;
    display: inline-flex;
}

.Product_ProductPanel__2l0Ov img {
    width: 50%;
}

.Product_ProductPanel__2l0Ov ul {
    text-align: center;
    list-style-position: inside;
}

.Product_ProductPanel__2l0Ov {
    /* max-width: 400px; */
    margin: auto;
}

.Product_ProductTitle__2f3NJ {
    font-size: 36px;
    font-weight: bold;
}

.Product_ProductSubitle__1_j7v {
    text-transform: none;
    font-family: "Trajan Pro Regular", sans-serif;
    letter-spacing: 0px;
    font-size: 24px;
}

.Product_Credits__2fafC {
    text-transform: none;
    font-family: "Trajan Pro Regular", sans-serif;
    letter-spacing: 0px;
    color: white;
    font-size: 12px;
}

@media (max-width: 812px) {
    .Product_Product__2vNml {
        display: inline-block;
    }
}

.Content_Content__2Kj17 {
    width: 100%;
    /*
    margin: auto;
    height: 500px;
    */
    margin:auto;
    overflow: scroll;
    text-align: center;
    overflow: hidden;
    padding-bottom: 50px;
}

/* http://stephen.io/mediaqueries/#iPhone */
/* iphone 8 */
@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px) {
    .Content_Content__2Kj17 {
        width: 375px;
        /* height: 400px; */
    }
}

/* iPhone X */
@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3) {
    .Content_Content__2Kj17 {
        width: 375px;
        /* height: 500px; */
    }
}

/*
@media (min-width: 500px) and (min-height: 400px) {
    .Content {
        width: 350px;
        height: 300px;
    }
}

@media (min-width: 500px) and (min-height: 401px) {
    .Content {
        width: 450px;
        height: 600px;
    }
}

@media (min-width: 1000px) and (min-height: 700px) {
    .Content {
        width: 700px;
        height: 800px;
    }
}
*/

.Logo_Logo__1UMks {
    color: white;
    background-color: black;
    padding: 1px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 36px;
    font-weight: bold;
}

.Logo_Logo__1UMks img {
    height: 100%;
}

@media (max-width: 750px) {
    .Logo_Logo__1UMks {
        font-size: 24px;
    }
}

.Header_Header__1FLHe {
    height: 48px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;

    text-transform: uppercase;
    font-family: "Futura Condensed PT Medium", sans-serif;
    letter-spacing: 1px;
    color: white;
    box-shadow: 0 11px 50px -6px rgba(0, 0, 0, .5);
}

.Header_Header__1FLHe nav {
    height: 100%;
}

.Header_Logo__KkMCM {
    height: 80%;
}

@media (max-width: 499px) {
    .Header_DesktopOnly__1bwod {
        /* display: none; */
    }
}

.NavigationItems_NavigationItems__33zIu {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 100%;
}

@media (min-width: 500px) {
    .NavigationItems_NavigationItems__33zIu {
        flex-flow: row;
    }
}

.NavigationItem_NavigationItem__FgsWJ {
    color: white;
    text-decoration: none;
    margin: 0;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
}

.NavigationItem_NavigationItem__FgsWJ a {
    color: white;
    text-decoration: none;
    height: 100%;
    width: 100%;
    padding: 3px 10px;
    border-bottom: 1px solid transparent;
    display: block;
}

.NavigationItem_NavigationItem__FgsWJ a:hover,
.NavigationItem_NavigationItem__FgsWJ a:active,
.NavigationItem_NavigationItem__FgsWJ a.NavigationItem_active__161Kz {
    background-color: #000000;
    border-bottom: 1px solid #FFFFFF;
    color: white;
}

/*
.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.NavigationItem a {
    color: white;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    color: #40A4C8;
}

@media (min-width: 500px) {
    .NavigationItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }

    .NavigationItem a {
        color: white;
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    }

    .NavigationItem a:hover,
    .NavigationItem a:active,
    .NavigationItem a.active {
        background-color: #000000;
        border-bottom: 4px solid #40A4C8;
        color: white;
    }
}
*/

.Footer_Footer__2KNFd {
    height: 28px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    color: white;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
    font-size: 12px;

    text-transform: uppercase;
    font-family: "Futura Condensed PT Medium", sans-serif;
    letter-spacing: 0px;
    color: white;
}

.Footer_Footer__2KNFd nav {
    height: 100%;
}

.Footer_Logo__FDTuY {
    height: 80%;
}


@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : landscape) {
    .Footer_Footer__2KNFd {
        position: relative;
    }
}

/* iPhone X landscape */
@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3)
and (orientation : landscape) {
    .Footer_Footer__2KNFd {
        position: relative;
    }
}

@media (max-height: 470px) {
    .Footer_Footer__2KNFd {
        position: relative;
    }
}

.Audio_Audio__vk2Uu {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 100px;
}

.Audio_Audio__vk2Uu p {
    color: white;
    padding-bottom: 24px;
}

.Audio_Audio__vk2Uu a,
.Audio_Audio__vk2Uu a:hover,
.Audio_Audio__vk2Uu a:active,
.Audio_Audio__vk2Uu a.Audio_active__24w8A {
    color: white;
}

.Audio_AudioTitle__2hsdn {
    font-size: 36px;
    font-weight: bold;
    color: white;
    /* padding-bottom: 20px; */
}

/*
@media (max-width: 812px) {
    .Audio {
        display: inline-block;
    }
}
*/

