.Header {
    height: 48px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;

    text-transform: uppercase;
    font-family: "Futura Condensed PT Medium", sans-serif;
    letter-spacing: 1px;
    color: white;
    box-shadow: 0 11px 50px -6px rgba(0, 0, 0, .5);
}

.Header nav {
    height: 100%;
}

.Logo {
    height: 80%;
}

@media (max-width: 499px) {
    .DesktopOnly {
        /* display: none; */
    }
}
