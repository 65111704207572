.Content {
    width: 100%;
    /*
    margin: auto;
    height: 500px;
    */
    margin:auto;
    overflow: scroll;
    text-align: center;
    overflow: hidden;
    padding-bottom: 50px;
}

/* http://stephen.io/mediaqueries/#iPhone */
/* iphone 8 */
@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px) {
    .Content {
        width: 375px;
        /* height: 400px; */
    }
}

/* iPhone X */
@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3) {
    .Content {
        width: 375px;
        /* height: 500px; */
    }
}

/*
@media (min-width: 500px) and (min-height: 400px) {
    .Content {
        width: 350px;
        height: 300px;
    }
}

@media (min-width: 500px) and (min-height: 401px) {
    .Content {
        width: 450px;
        height: 600px;
    }
}

@media (min-width: 1000px) and (min-height: 700px) {
    .Content {
        width: 700px;
        height: 800px;
    }
}
*/
