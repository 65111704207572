.Product {
    margin: auto;
    /*
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    */
    /*
    background-color: white;
    box-sizing: border-box;
    border-radius: 5px;
    */
    /* width: 80%; */
    /* max-width: 800px; */
    /* padding: 8px; */
    padding-top: 20px;
    color: white;
    /* font-size: 12px; */
    font-weight: normal;
    display: inline-flex;
}

.ProductPanel img {
    width: 50%;
}

.ProductPanel ul {
    text-align: center;
    list-style-position: inside;
}

.ProductPanel {
    /* max-width: 400px; */
    margin: auto;
}

.ProductTitle {
    font-size: 36px;
    font-weight: bold;
}

.ProductSubitle {
    text-transform: none;
    font-family: "Trajan Pro Regular", sans-serif;
    letter-spacing: 0px;
    font-size: 24px;
}

.Credits {
    text-transform: none;
    font-family: "Trajan Pro Regular", sans-serif;
    letter-spacing: 0px;
    color: white;
    font-size: 12px;
}

@media (max-width: 812px) {
    .Product {
        display: inline-block;
    }
}
